@import url(https://webincludes.dot.state.fl.us/bootstrap/css/bootstrap.css);
@import url(https://webincludes.dot.state.fl.us/fdot/css/fdot.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    background-color: white  !important;
    /* margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .bubble {
    -webkit-animation: expand .75s ease-in-out infinite;
            animation: expand .75s ease-in-out infinite;
    border-radius: 20px;
    display: inline-block;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    margin: 0 3px;
    width: 20px;
    height: 20px;
  }
  
  .bubble:nth-child(1) {
    background: #27ae60;
  }
  
  .bubble:nth-child(2) {
    -webkit-animation-delay: 180ms;
            animation-delay: 180ms;
    background: #f1c40f;
  }
  
  .bubble:nth-child(3) {
    -webkit-animation-delay: 360ms;
            animation-delay: 360ms;
    background: #e67e22;
  }
  
  .bubble:nth-child(4) {
    -webkit-animation-delay: 540ms;
            animation-delay: 540ms;
    background: #2980b9;
  }
  
  @-webkit-keyframes expand {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.55);
              transform: scale(1.55);
    }
  }
  
  @keyframes expand {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.55);
              transform: scale(1.55);
    }
  }
  
