  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .bubble {
    animation: expand .75s ease-in-out infinite;
    border-radius: 20px;
    display: inline-block;
    transform-origin: center center;
    margin: 0 3px;
    width: 20px;
    height: 20px;
  }
  
  .bubble:nth-child(1) {
    background: #27ae60;
  }
  
  .bubble:nth-child(2) {
    animation-delay: 180ms;
    background: #f1c40f;
  }
  
  .bubble:nth-child(3) {
    animation-delay: 360ms;
    background: #e67e22;
  }
  
  .bubble:nth-child(4) {
    animation-delay: 540ms;
    background: #2980b9;
  }
  
  @keyframes expand {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.55);
    }
  }
  